import InfoCard from "./InfoCard";
import {useSelector} from "react-redux";

const InfoManagers = () => {

    const data = useSelector(state => state.Dashboard.next_order)

    return (
        <>
            <div className="col-xxl-4 col-lg-4 col-md-4 mb-md-4 mb-3">
                <InfoCard title={"Ваш менеджер з розрахунків"}
                          name={`${data.manager.last_name} ${data.manager.first_name}`}
                          phone={`${data.manager.phone}`}
                          email={`${data.manager.email}`}/>
            </div>
            <div className="col-xxl-4 col-lg-4 col-md-4 mb-md-4 mb-3">
                <InfoCard title={"Ваш проєкт-менеджер"}
                          name={`${data.project_manager.last_name} ${data.project_manager.first_name}`}
                          phone={`${data.project_manager.phone}`}
                          email={`${data.project_manager.email}`}/>
            </div>
            <div className="col-xxl-4 col-lg-4 col-md-4 mb-md-4 mb-3">
                <InfoCard title={"Керівник відділу продажу"} name={"Бараннік Дар'я"}
                          phone={"+38 (067) 4018765"}
                          email={"barannik@vst.net.ua"}/>
            </div>
        </>
    )
}

export default InfoManagers